/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// global.scss
@import "~swiper/scss";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/keyboard";
@import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/zoom";
@import "~@ionic/angular/css/ionic-swiper";
@import "./dropkick.css";

// http://ionicframework.com/docs/theming/

// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

.red {
  color: red;
}

.hand-cursor {
  cursor: pointer;
}

.burger-menu-icon {
  position: absolute;
  right: 0.4rem;
}

.toolbar-ios ion-title {
  padding: 0;
  text-align: center;
}

.menu-icon {
  color: var(--tgyn-bright-blue);
  font-size: 2em;
  margin-right: 0.2rem;
  cursor: pointer;
}

.center-image {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

ion-spinner {
  display: block;
  margin: auto;
}

.custom-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  width: 12%;
  height: 12%;
  //background: rgba(0, 0, 0, 0.8);
}

.custom-loading-spinner {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 94vh;
  position: absolute;
  z-index: 10001;
}

.arrow-circle {
  border-radius: 50%;
  background-color: var(--tgyn-white);
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-weight: bold;
}
.ion-md-arrow-back {
  padding-left: 0;
  text-align: center;
}

.menu-panel-button {
  background-color: var(--tgyn-bright-blue);
  color: var(--tgyn-white);
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid var(--tgyn-light-gray)
}

// Generic styles used in all the app.

.label-lbl {
  margin-left: 15px !important;
  margin-top: 0px;
}

.l-input {
  border: 2px solid rgba(31, 143, 241, 0.25);
  border-radius: 8px;
  --background: rgba(31, 143, 241, 0.12);
  --color: rgb(6, 180, 244);
  font-weight: 600;
  margin-top: -9px;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px !important;
  --padding-top: 10px;
}

.item-has-focus.label-floating.sc-ion-label-ios-h,
.item-has-focus .label-floating.sc-ion-label-ios-h,
.item-has-placeholder.sc-ion-label-ios-h:not(.item-input).label-floating,
.item-has-placeholder:not(.item-input) .label-floating.sc-ion-label-ios-h,
.item-has-value.label-floating.sc-ion-label-ios-h,
.item-has-value .label-floating.sc-ion-label-ios-h {
  margin-bottom: 5px;
}

.ion-focused.label-floating.sc-ion-label-md-h,
.ion-focused .label-floating.sc-ion-label-md-h,
.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h,
.item-has-placeholder.sc-ion-label-md-h:not(.item-input).label-floating,
.item-has-placeholder:not(.item-input) .label-floating.sc-ion-label-md-h,
.item-has-value.label-floating.sc-ion-label-md-h,
.item-has-value .label-floating.sc-ion-label-md-h {
  transform: translateY(-35%) scale(0.75);
  margin-left: 5px !important;
}

.item-has-focus,
.item-has-value {
  overflow: visible;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  /* padding-left: 93px; */
  margin-top: -13px;
  font-weight: bold;
  margin-left: 110px;
  font-size: 1.8rem;
}

.grid-item-3 {
  margin-top: -19px;
}
.content-title {
  font-weight: 800;
  text-align: center;
}
.more-info-button {
  text-align: center;
}

.content {
  color: var(--tgyn-gray);
  font-size: 2rem;
}

.secondary-title {
  font-weight: bold;
}

// .unit {
//   .title {
//     font-size: 1.8rem;
//     font-weight: bold;
//   }

//   .content {
//     font-size: 1.8rem;
//     color: var(--tgyn-gray);
//   }
// }

.slide-zoom {
  height: 100%;
  overflow-y: auto;
}
.more-detail-img {
  width: 154px;
  margin-top: 22px;
  float: right;
}

.head-content {
  margin-top: 2.5rem;
  margin-left: 1.7rem;
  font-size: 2rem;
  color: rgb(252, 177, 76);
  font-weight: bold;
}

.sub-content-text {
  font-size: 1.2rem;
  margin: 19px;
  text-align: justify;
  color: black;
  font-weight: 500;
}

.american-english-content-text {
  font-size: 1.9rem;
  margin: 19px;
  text-align: center;
}

.footer-content {
  font-size: 1.2rem;
  margin: 19px;
  text-align: justify;
  color: black;
  font-weight: 500;
}

//Exercises Common Styles

.flag-icon {
  font-size: 240%;
  cursor: pointer !important;

  &.active {
    --color: #e02c1f;
    color: #e02c1f;
  }

  &.grey {
    color: grey;
    --color: grey;
  }
}

.score {
  text-align: center;
  border: 2px solid var(--tgyn-white);
  background-color: var(--tgyn-white);
  .percent {
    font-size: 1.5rem;
  }
}

.good-score {
  color: green;
  border-color: green;
}

.ok-score {
  color: orange;
  border-color: orange;
}

.fail-score {
  color: red;
  border-color: red;
}

.correct {
  color: green;
}

.incorrect {
  color: red;
}

.exercise-action-row-buttons {
  ion-button {
    text-transform: initial;
    width: 120px;
    font-size: 20px;
    font-weight: bold;

    --border-color: rgb(244, 101, 6);
  }
}

//END Exercises Common Styles

.help-info-popover {
  width: 100%;
  padding: 0 !important;

  .help-info {
    position: absolute;
    // right: -2rem;
    // top: -2rem;
    font-size: 3rem;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 66%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 66%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 66%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

  figure {
    padding: 0;
    margin: 0;
  }
  .help-info-content {
    width: 100% !important;
  }
  .more-details-img {
    width: 50px;
  }
}
.popover-viewport {
  overflow-y: scroll;
  overflow-x: hidden;
}

.help-info-popover-container {
  --height: 80%;
  --width: 80%;
  overflow: unset;
  word-wrap: normal;
  border: 1px solid var(--tgyn-bright-blue);
  --max-width: 530px;
  --max-height: 975px;

  @media (max-width: 576px) {
    .popover-content {
      left: 0 !important;
      width: calc(100% - 12px) !important;
      margin: 0 6px !important;
    }

    .help-info-popover .help-info {
      right: 0;
    }
  }
}

.help-content {
  margin-left: auto;
  margin-right: auto;
}

.help-header {
  .toolbar-background {
    height: 0;
  }
}

ion-backdrop {
  background-color: black;
  opacity: 0.5 !important;
  visibility: visible !important;
  z-index: auto;
}

.popover-ios {
  ion-backdrop {
    opacity: 0.5 !important;
  }
}

.button-inner {
  text-transform: initial;
  font-weight: bold;
  font-size: var(--tgyn-button-text-size);
}

ion-content {
  font-size: var(--tgyn-content-size);
}

/*
 * Hide only visually, but have it
 * available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.underline {
  text-decoration: underline;
}

.remarketing-popover-container {
  --height: 630px;
  --width: 530px;
  overflow: unset;

  .remarketing-close {
    position: absolute;
    right: -0.5rem;
    top: -0.1rem;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 66%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 66%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 66%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    font-size: 2.5rem;
  }

  .popover-content {
    height: 80%;
    width: 80%;
    overflow: unset;
    word-wrap: normal;
    border: 1px solid var(--tgyn-bright-blue);
    max-width: 530px;
    max-height: 630px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 576px) {
    .popover-content {
      width: 92%;
      height: 80%;
    }
  }

  .remarketing-ad {
    font-size: 16pt;

    .head-content {
      font-size: 18pt;
      text-align: center;
      font-weight: bold;
      margin-top: 1rem;
    }
    .american-english-head-contnet {
      color: #5f91ec;
      font-size: 14pt;
      text-align: center;
      font-weight: bold;
      margin-top: 1rem;
    }
    .american-english-img-content {
      text-align: center;
      font-size: 13px;
      margin: 29px;
    }
    .the-word-combination-head-contnet {
      color: darkgreen;
      font-size: 14pt;
      text-align: center;
      font-weight: bold;
      margin-top: 1rem;
    }
    .tgyn-series-ads-head {
      color: orange;
      font-size: 14pt;
      text-align: center;
      font-weight: bold;
      margin-top: 1rem;
    }
    .sentences-topic {
      margin-left: 78px;
      font-size: 1.8rem;
    }
    .tgyn-series-ads-subhead-1 {
      color: orange;
      font-size: 15pt;
      text-align: center;
    }
    .tgyn-series-ads-subhead-2 {
      color: blue;
      font-size: 16pt;
      text-align: center;
    }
    .tgyn-series-ads-subhead-3 {
      text-align: center;
    }
    .tgyn-small-icon {
      text-align: center;
    }
    .tgyn-series-book-img {
      margin-top: -21px;
    }
    @media (min-width: 570px) {
      .head-content {
        font-size: 16pt;
        margin-top: 2rem;
      }
    }

    @media (max-width: 375px) {
      .american-english-book-img {
        width: 326px;
        margin: -31px;
      }
      .american-english-head-contnet {
        color: #5f91ec;
        font-size: 10pt;
        text-align: center;
        font-weight: bold;
        margin-top: 1rem;
      }
      .american-english-content-text {
        font-size: 1.7rem;
        margin: 19px;
        text-align: center;
      }
      .sentences-topic {
        margin-left: 10px;
        font-size: 1.8rem;
      }
    }
    .word-combination-content-text {
      margin: 19px;
      text-align: center;
    }
    .more-info-button {
      text-align: center;
      padding: 0.5rem;
      /* line-height: 5rem; */
      height: 5rem;
      margin-left: auto;
      margin-right: auto;
      width: 20rem;
      display: block;
      a {
        color: white;
        text-decoration: none;
        font-weight: bold;
        line-height: 4rem;
      }
    }
    .footer-content {
      img {
        width: 50%;
      }
    }
  }
}

.more-info-modal {
  --height: 45%;
}

.menu-page-popover {
  @media (max-width: 576px) {
    --width: calc(100vw - 50px);
    --min-width: calc(100vw - 50px);
  }

  --background: #0000005c;

  &::part(content) {
    backdrop-filter: blur(12px);
    --width: 70%;
  }

  ion-icon {
    position: fixed;
    z-index: 100;
    right: 1.5rem;
    top: 1rem;
    color: var(--tgyn-bright-blue);
    font-size: 20pt;
    cursor: pointer;
  }
}

.menu-page-popover.popover-ios {
  .popover-content {
    left: 5% !important;
  }
  ion-icon {
    top: 8.2%;
    right: 9.2%;
  }
  @media (min-width: 768px) {
    ion-icon {
      top: 6.2%;
      right: 7.5%;
    }
  }
}

.reflection-popover-container .popover-content {
  height: 25rem;
}

tooltip-box {
  margin: auto !important;
  background-color: rgb(250, 248, 248) !important;
  color: rgb(3, 3, 3) !important;
  border-radius: 5px !important;
  border-color: #b9bbbd !important;
  border-style: solid !important;
  width: 25% !important;
  right: 33% !important;
}

.flag-all {
  text-align: center;
}
.what-flag {
  text-align: center !important;
  text-decoration: underline;
  font-size: 55%;
  cursor: pointer;
  ion-content {
    font-size: 14px;
    text-decoration: none;
  }
}

.foreground-color {
  color: #757575;
}

.toolbar-ios {
  padding: 4px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 60px;
}

image-viewer.ion-page ion-navbar .bar-button-default {
  color: black;
}

.book-img {
  width: 60%;
  margin-left: 19%;
}

ion-icon.icon.icon-ios.ion-ios-home {
  font-size: 30px;
}
ion-icon.icon.icon-md.ion-md-home {
  font-size: 30px;
}

ion-navbar.toolbar.toolbar-md.show-navbar {
  background-color: var(--ion-color-primary);
}

ion-navbar.toolbar.toolbar-ios.show-navbar {
  background-color: var(--ion-color-primary);
}

ion-buttons.home-btn.bar-buttons.bar-buttons-md {
  margin-left: 1.5rem;
}

.Title-plus-screen-6 {
  color: var(--tgyn-orange);
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  font-size: 20px;
}
.subTitle-plus-screen-6 {
  padding: 3%;
  color: var(--tgyn-orange);
}

.div-title {
  color: var(--tgyn-orange);
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 4rem;
  text-align: center;
  font-size: 3rem;
}
.div-content {
  padding: 3%;
  color: var(--tgyn-orange);
  font-size: 2rem;
}

.action-sheet-wrapper {
  margin-top: 16%;
}

.action-sheet-md .action-sheet-group:last-child {
  padding-bottom: 0.8rem;
  position: absolute;
  top: -6%;
  right: 0%;
  width: 100%;
  padding-left: 77%;
}

.label-name {
  font-size: 1.5rem;
  margin-right: 40% !important;
  margin-bottom: 2px !important;
  text-transform: uppercase;
}

.forgot-password-modal {
  .modal-wrapper {
    height: 100% !important;
    z-index: 9999;
  }
}

.stripe-modal {
  .modal-wrapper {
    height: 42% !important;
  }
}

.help-lesson-modal {
  --height: calc(100vh - 140px);
  --width: calc(100vw - 25px);
}

.custom-alert-modal {
  .custom-alert-wrapper {
    padding: 1rem;
    .alert-button {
      border: 1px solid var(--tgyn-bright-blue) !important;
    }
    .custom-alert-sub-title {
      color: var(--tgyn-gray);
      font-size: 16pt;
    }
    .custom-alert-button-group {
      justify-content: center;
      z-index: 30002;
      position: relative;
    }
    .custom-alert-head {
      text-align: center;
      font-size: 16pt;
      margin: 0px 10px 0px 10px;
    }
  }

  --border-radius: 150px;
  .modal-wrapper {
    border-radius: 150px;
  }

  --height: 300px;
  --width: calc(100vw - (100vw - 300px));

  .main-wrap {
    text-align: center !important;
    margin-top: 80px;
  }

  .alert-head {
    text-align: center !important;
  }

  .timer {
    // margin-top: 50px;
    position: fixed;
    // top: 25%;
    // left: 24%;

    .base-timer {
      position: relative;
      height: 300px;
      width: 300px;
      margin: auto;
    }

    .base-timer__circle {
      fill: none;
      stroke: none;
    }

    .base-timer__path-elapsed {
      stroke-width: 7px;
      stroke: grey;
    }

    .base-timer__label {
      position: absolute;
      width: 300px;
      height: 300px;
      top: -95px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 35px;
    }

    .base-timer__path-remaining {
      stroke-width: 7px;
      stroke-linecap: round;
      transform: rotate(90deg);
      transform-origin: center;
      transition: 1s linear all;
      stroke: currentColor;
      color: var(--ion-color-primary);
    }

    .base-timer__svg {
      transform: scaleX(-1);
    }
  }
}

.avatar-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.logged-user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--tgyn-gray);
  font-size: 17px;
}

.logged-user img {
  height: 32px;
  width: 32px;
  margin: 16px;
}

.logged-user label {
  margin: 8px;
  font-weight: bold;
  color: gray;
}

.alert-not-logged {

  .alert-button-group{
    button {
      --border-radius: 4px;
      border-radius: var(--border-radius);
      --background: var(--ion-color-primary, #3880ff);
      background: var(--background);
      color: white;

    }
  }
  .alert-button-group.sc-ion-alert-md{
    justify-content: center;
  }

  .alert-button.sc-ion-alert-md{
    --background-activated: transparent;
    --background-hover: var(--ion-color-primary-contrast, #fff);
    --background-focused: var(--ion-color-primary-contrast, #fff);
    --background-activated-opacity: 0;
    --background-focused-opacity: .24;
    --background-hover-opacity: .08;
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
}

.global-home-icon {
  width: 30px;
  height: 32px;
}

// This is the "media" we should using in all the app.
@media (min-width: 768px) {
  tooltip-box {
    margin: auto !important;
    background-color: rgb(250, 248, 248) !important;
    color: rgb(3, 3, 3) !important;
    border-radius: 5px !important;
    border-color: #b9bbbd !important;
    border-style: solid !important;
    width: 80% !important;
    left: 10% !important;
  }

  .help-lesson-modal {
    --height: calc(100vh - 100px);
    --width: calc(100vw - 600px);
  }

  .label-lbl {
    margin-left: 15px !important;
    padding-top: 4px;
  }

  .margin-left-title {
    margin-left: 10%;
    max-width: 80%;
  }

  .score {
    text-align: center;
    border: 2px solid var(--tgyn-white);
    background-color: var(--tgyn-white);
    .percent {
      font-size: 2rem;
    }
  }
}

@media (max-width: 320px) {
  .head-content {
    margin-top: 2.5rem;
    margin-left: 1.7rem;
    font-size: 1.5rem;
    color: #fcb14c;
  }

  .sub-content-text {
    font-size: 14pt;
    margin: 19px;
    text-align: justify;
    color: black;
    font-weight: 500;
  }

  .footer-content {
    font-size: 1.4rem;
    margin: 19px;
    text-align: justify;
    color: black;
    font-weight: 500;
  }
}

@media (max-width: 576px) {
  .reflection-popover-container .popover-content {
    width: 80%;
  }

  .help-info {
    position: absolute;
    // right: 4rem;
    // top: -2rem;
    background-color: transparent;
    font-size: 3rem;
  }

  .remarketing-close {
    position: absolute;
    right: 0rem;
    top: -2rem;
    font-size: 3rem;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    /* padding-left: 93px; */
    margin-top: -13px;
    font-weight: bold;
    margin-left: 19px;
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 320px) and (max-height: 568px) {
  .menu-panel-button {
    background-color: var(--tgyn-bright-blue);
    color: var(--tgyn-white);
    font-weight: bold;
    font-size: 15px;
    border-bottom: 1px solid var(--tgyn-light-gray)
  }
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  .forgot-password-modal {
    .modal-wrapper {
      height: 42% !important;
      z-index: 9999;
    }
  }

  .more-info-modal {
    .modal-wrapper {
      height: 42% !important;
      z-index: 9999;
    }
  }

  .stripe-modal {
    --height: 380px;
  }
}

@media only screen and (min-height: 800px) and (min-width: 800px) {
  .forgot-password-modal {
    .modal-wrapper {
      height: 55% !important;
      z-index: 9999;
    }
  }

  .more-info-modal {
    .modal-wrapper {
      height: 35% !important;
      z-index: 9999;
    }
  }

  .stripe-modal {
    .modal-wrapper {
      height: 42% !important;
    }
  }
}
